<template>
  <div>
    <Alert />
    <Header />
    <RedirectModal />
    <Navigation />
    <v-main>
      <div style="margin: 15px 0;width: 100%; padding: 13px;color: #fff;background-color: #ee9500;text-align:center; font-weight:600">
						This tool will be discontinued in 30 days. If you need more time, please contact support					
      </div>
      <slot />
    </v-main>
  </div>
</template>

<script>
import Alert from "@/components/shared/Alert";
import Header from "@/components/owner/Header";
import RedirectModal from "@/components/owner/RedirectModal";
import Navigation from "@/components/owner/Navigation";

export default {
  components: {
    Alert,
    Header,
    RedirectModal,
    Navigation,
  },
  created() {
    document.title = "Builderall Delivery"
  },
  async mounted() {
    await this.$auth.getUser();
    if (this.$auth.ownerHasStore() == false && this.$route.path != "/owner/store") {
      this.$store.commit("toggle/toggleRedirectModal", true);
    }
    if(this.$auth.user()) {
      this.$i18n.locale = this.$auth.user().locale.substring(0,2);
    }
  },
  
};
</script>

<style>
</style>
