<template>
  <div>
    <Alert />
    <DefaultHeader />
    <DefaultCart />
    <DefaultNavigation />
    <AddressModal />
    <v-main>
      <div style="margin: 15px 0;width: 100%; padding: 13px;color: #fff;background-color: #ee9500;text-align:center; font-weight:600">
						This tool will be discontinued in 30 days. If you need more time, please contact support					</div>
      <slot />
    </v-main>
  </div>
</template>

<script>
import Alert from "@/components/shared/Alert";
import DefaultHeader from "@/components/shop/default/Header";
import DefaultCart from "@/components/shop/default/Cart";
import DefaultNavigation from "@/components/shop/default/Navigation";
import AddressModal from "@/components/shop/default/AddressModal";
export default {
  components: {
    Alert,
    DefaultHeader,
    DefaultCart,
    DefaultNavigation,
    AddressModal
  }
};
</script>

<style>
</style>
